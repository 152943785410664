<template>
  <div class="login">
    <section class="section is-main-section columns is-centered is-desktop">
      <div class="card column is-4 is-half-desktop">
        <div class="card-content has-text-centered">
          <p>
            <img src="/logo.png" alt="logo" width="200px">
          </p>
          <br>
          <p class="title">
            PUSPITA
          </p>
          <hr>
          <b-field>
              <b-input placeholder="Email atau Username"
                  @keyup.enter.native="handleLogin"
                  v-model="formData.email">
              </b-input>
          </b-field>
          <b-field>
              <b-input type="password"
                  placeholder="Password reveal input"
                  v-model="formData.password"
                  @keyup.enter.native="handleLogin"
                  password-reveal>
              </b-input>
          </b-field>
          <div>
            <div class="has-text-left">
              <a :href="api">Forgot Password</a>
            </div>
            <div class="has-text-right">
              <b-button type="is-primary" @click="handleLogin">Masuk</b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      formData: {
        email: null,
        password: null
      }
    }
  },
  computed: {
    api () {
      const api = axios.defaults.baseURL.split('/')
      api.pop()
      return `${api.join('/')}/forgot-password`
    },
    titleStack () {
      return ['Login']
    }
  },
  mounted () {
  },
  methods: {
    handleLogin () {
      axios.post('/login', this.formData).then(response => {
        console.log(response.data.data)
        this.$store.dispatch('login', response.data.data)
        this.$router.push({ name: 'home' })
      }).catch(error => {
        const message = error.response === undefined ? error.toString() : error.response.data.message
        console.log(message)
        this.$buefy.toast.open({
          message: message,
          type: 'is-danger'
        })
      }) // credentials didn't match
    }
  }
}
</script>

<style scoped>

@media only screen and (min-width: 1024px) {
  .login {
    margin-left: -14rem;
  }
}

</style>
